import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/pt-br'

dayjs.extend(relativeTime)
dayjs.locale('pt-br')

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function timeSince(date: Date) {
  const now = new Date().valueOf()
  const pastDate = new Date(date).valueOf()
  const seconds = Math.floor((now - pastDate) / 1000)

  let interval = Math.floor(seconds / 31536000)
  if (interval > 1) {
    return `${interval} anos`
  }
  interval = Math.floor(seconds / 2592000)
  if (interval > 1) {
    return `${interval} meses`
  }
  interval = Math.floor(seconds / 86400)
  if (interval > 1) {
    return `${interval}d`
  }
  interval = Math.floor(seconds / 3600)
  if (interval > 1) {
    return `${interval}h`
  }
  interval = Math.floor(seconds / 60)
  if (interval > 1) {
    return `${interval}m`
  }
  return `${Math.floor(seconds)}s`
}

export const formatDate = (dateString: string) => {
  const date = dayjs(dateString)

  if (date.isSame(dayjs(), 'day')) {
    return 'HOJE'
  }

  if (date.isSame(dayjs().subtract(1, 'day'), 'day')) {
    return 'ONTEM'
  }

  if (date.isSame(dayjs().subtract(2, 'day'), 'day')) {
    return date.format('dddd').toLocaleUpperCase()
  }

  return date.format('DD/MM/YYYY')
}
