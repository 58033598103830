import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'
import { Spinner } from '@/components/Spinner'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors disabled:cursor-not-allowed focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-zinc-800 disabled:bg-fs-red-400 text-zinc-800-foreground shadow hover:bg-zinc-800/50',
        destructive:
          'bg-fs-red text-destructive-foreground shadow-sm hover:bg-fs-red/90',
        outline:
          'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
        secondary:
          'border border-fs-red-400 text-fs-red-400 hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        'fs-red': 'bg-fs-red text-white hover:bg-fs-red-400',
        action: 'w-full bg-zinc-200 hover:bg-zinc-400 text-zinc-950 gap-2 cursor-pointer',
        success: 'bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-700'
      },
      size: {
        default: 'h-9 px-4 py-2',
        xsm: 'h-6 rounded-md px-2 text-xs',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        xlg: 'h-12 rounded-md px-8',
        icon: 'size-9'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean
  isloading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  size,
  asChild = false,
  isloading = false,
  disabled = false,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : 'button'

  return (
    <Comp
      disabled={disabled || isloading}
      className={cn(buttonVariants({ variant, size, className }))}
      ref={ref}
      {...props}
    >
      {isloading ? <Spinner /> : props.children}
    </Comp>
  )
}
)
Button.displayName = 'Button'

export { Button, buttonVariants }
