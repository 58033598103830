'use client'
import React, { useRef } from 'react'
import {
  Carousel,
  CarouselContent,
  CarouselItem
} from '@/components/ui/carousel'
import Autoplay from 'embla-carousel-autoplay'

type PropType = {
  slides: React.ReactNode[]
}

export const SlidesBabies: React.FC<PropType> = (props) => {
  const { slides } = props

  const plugin = useRef(
    Autoplay({
      delay: 4000,
      playOnInit: true
    })
  )

  return (
    <Carousel
      plugins={[ plugin.current ]}
      onMouseEnter={plugin.current.stop}
      onMouseLeave={() => plugin.current.play()}
      opts={{
        loop: true,
        align: 'start'
      }}
      className="w-full h-auto relative">
      <CarouselContent className="px-1">
        {slides.map((item, index) => (
          <CarouselItem key={index} className="2xl:basis-1/10 xl:basis-1/6 lg:basis-1/6 md:basis-1/4 basis-1/3">
            {item}
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  )
}
