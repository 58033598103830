'use client'

import React, { useRef } from 'react'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselDots
} from '@/components/ui/carousel'
import Autoplay from 'embla-carousel-autoplay'

type PropType = {
  slides: React.ReactNode[]
}

export const Slides: React.FC<PropType> = (props) => {
  const { slides } = props

  const plugin = useRef(
    Autoplay({
      delay: 4000,
      playOnInit: true
    })
  )

  return (
    <Carousel
      plugins={[ plugin.current ]}
      onMouseEnter={plugin.current.stop}
      onMouseLeave={() => plugin.current.play()}
      opts={{
        loop: true,
        align: 'center'
      }}
    >
      <CarouselContent >
        {slides.map((item, index) => (
          <CarouselItem key={index}>
            {item}
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselDots />
    </Carousel>
  )
}
